import React from "react"
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../layout";


// markup
const IndexPage = () => {
  return (<Layout>
    <Container className="fh-element fh">
      <Row className={"fh-element align-items-center"}>
        <Col xs="auto" className="mx-auto">
          {"404 - Page Not Found"}
        </Col>
      </Row>
    </Container>
  </Layout>);
}

export default IndexPage
